import styled from '@emotion/styled';
import { Link, Breadcrumbs, Container } from '@mui/material';
import type { CSSProperties, FC } from 'react';
import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import type { UserObject } from '../../entities/User';
import BottomEyecatch from '../global/BottomEyecatch';
import Footer from '../global/Footer';
import Header from '../global/Header';
import type { Links, SectionAchievePoint } from '../../types';
import ScrollTop from '../global/ScrollTop';
import * as pageTitles from '../../config/pageTitles';
import { getCurrentUser } from '../../clients/api';
import LessonChatDialog from '../curriculums/LessonChatDialog';
import LessonAchieveDialog from '../curriculums/LessonAchieveDialog';
import useLesson from '../../hooks/useLesson';
import PromotionBanner from '../promotion/fixedBanner';
import SignupDialog from '../../../src/components/global/SignupDialog';
import { useSignDialogContextState, SignupDialogProvider } from 'src/contexts/SignupDialogContext';

export interface Props {
  children: React.ReactNode;
  user?: UserObject | null;
  remoteUser?: boolean;
  noContainer?: boolean;
  breadcrumbs?: Links;
  sectionAchievePoint?: SectionAchievePoint;
  sectionInbound?: boolean;
}

const itemStyle: CSSProperties = {
  maxWidth: '15em',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
  whiteSpace: 'nowrap',
};

const MainLayout: FC<Props> = ({
  children,
  user: defaultUser,
  noContainer = false,
  remoteUser = false,
  breadcrumbs = [],
  sectionInbound,
  sectionAchievePoint,
}) => {
  const router = useRouter();
  const [user, setUser] = useState(defaultUser);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const { currentLessonId, push: pushLesson } = useLesson({
    previewAccessToken: router.query.previewAccessToken as string | undefined,
  });
  const [openAchieveDialog, setOpenAchieveDialog] = useState(false);
  const [achieveDialogPoint, setAchieveDialogPoint] = useState('');
  const [nextLesson, setNextLesson] = useState('');
  const signupDialog = useSignDialogContextState();

  const handleAchieveDialog = (handle: boolean, nextLesson: string | null, achievePoint: string | null) => {
    if (handle) {
      setAchieveDialogPoint(achievePoint ?? '');
      setOpenAchieveDialog(true);
      setNextLesson(nextLesson ?? '');
    } else {
      setOpenAchieveDialog(false);
      pushLesson(nextLesson);
    }
  };

  useEffect(() => {
    if (!remoteUser) {
      setIsLoadingUser(false);
      return;
    }

    const fetchUser = async () => {
      setIsLoadingUser(true);
      const user = await getCurrentUser();
      setUser(user);
      setIsLoadingUser(false);
    };

    fetchUser();
  }, [remoteUser]);

  const canShowBannerPage =
    !!router.pathname.match(/^\/?$/) ||
    !!router.pathname.match(/^\/about\/?$/) ||
    !!router.pathname.match(/^\/downloadThanks\/?$/) ||
    !!router.pathname.match(/^\/account\/?.*$/) ||
    !!router.pathname.match(/^\/cases\/?.*$/) ||
    !!router.pathname.match(/^\/curriculums\/?.*$/) ||
    !!router.pathname.match(/^\/news\/?.*$/) ||
    !!router.pathname.match(/^\/lectures\/?.*$/) ||
    !!router.pathname.match(/^\/whitepapers\/?.*$/);

  return (
    <div className="main-layout">
      <Header user={user ?? null} isLoadingUser={isLoadingUser} id="main-header"/>
      <ContentWrapper>
        {breadcrumbs.length > 0 && (
          <BreadcrumbsWrapper>
            <Container>
              <Breadcrumbs separator={<span style={itemStyle}>›</span>} aria-label="breadcrumb">
                {[[pageTitles.top, '/'], ...breadcrumbs].map(([label, href], i) =>
                  href ? (
                    <NextLink href={href} passHref key={i}>
                      <Link underline="hover" color="inherit" sx={itemStyle}>
                        {label}
                      </Link>
                    </NextLink>
                  ) : (
                    <span key={i} style={itemStyle}>
                      {label}
                    </span>
                  )
                )}
              </Breadcrumbs>
            </Container>
          </BreadcrumbsWrapper>
        )}
        <SignupDialogProvider {...signupDialog}>
          {noContainer ? children : <Container sx={{ position: 'relative', zIndex: 1 }}>{children}</Container>}
          {!isLoadingUser && !user && router.pathname !== '/thanks' && <BottomEyecatch isLoggedIn={!!user} />}
          <Footer />
          <ScrollTop />
          <LessonChatDialog
            lessonId={currentLessonId}
            user={user ?? null}
            onClose={() => pushLesson(null)}
            onNext={pushLesson}
            handleAchieveDialog={handleAchieveDialog}
            sectionAchievePoint={sectionAchievePoint}
            sectionInbound={sectionInbound}
          />
          <LessonAchieveDialog
            open={openAchieveDialog}
            onClose={() => handleAchieveDialog(false, null, null)}
            onNext={() => handleAchieveDialog(false, nextLesson, null)}
            achievePoint={achieveDialogPoint}
            sectionInbound={sectionInbound}
            nextLesson={nextLesson}
          />
          {canShowBannerPage && <PromotionBanner />}
          <SignupDialog
            open={signupDialog.isOpen}
            onClose={signupDialog.close}
            user={user ?? null}
            showClose={signupDialog.isCloseShown}
            title={signupDialog.title}
          />
        </SignupDialogProvider>
      </ContentWrapper>
    </div>
  );
};

export default MainLayout;

const BreadcrumbsWrapper = styled.div`
  background: #f7f7f7;
  display: flex;
  padding: 10px 0;
  position: relative;
  z-index: 2;
`;

const ContentWrapper = styled.div`
  margin-top: 131px;

  @media (max-width: 1069px) {
    margin-top: 149px;
  }

  @media (max-width: 899px) {
    margin-top: 110px;
  }

  @media (max-width: 599px) {
    margin-top: 101px;
  }
`;
