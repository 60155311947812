import React, { useState, useEffect } from 'react';
import { Box, IconButton, Fade } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import NextLink from 'next/link';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';

const BANNER_PATH = '/seminars/202504_nextfoodvision/';
const UTM_PARAMS = '?utm_source=seminar&utm_medium=mov&utm_campaign=float';
const STORAGE_KEY = 'banner_closed_until';

interface FixedBannerProps {
  defaultOpen?: boolean;
}

interface ResponsiveImageProps {
  srcPc: string;
  srcSp: string;
}

const FixedBanner: React.FC<FixedBannerProps> = ({ defaultOpen = true }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [isVisible, setIsVisible] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const closedUntil = localStorage.getItem(STORAGE_KEY);
    if (closedUntil && new Date(closedUntil) > new Date()) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollY, innerHeight, document } = window;
      const { scrollHeight } = document.documentElement;
      const bottomReached = scrollY + innerHeight >= scrollHeight;
      setIsVisible(scrollY > 100 && !bottomReached);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleClose = () => {
    const threeDaysLater = new Date();
    threeDaysLater.setDate(threeDaysLater.getDate() + 3);
    localStorage.setItem(STORAGE_KEY, threeDaysLater.toISOString());
    setIsOpen(false);
  };

  if (!isOpen || router.pathname === BANNER_PATH) return null;

  return (
    <Fade in={isVisible} timeout={500}>
      <CardContainer>
        <NextLink href={`${BANNER_PATH}${UTM_PARAMS}`} passHref>
          <ResponsiveImage
            srcPc="/promotion/banner_pc.png"
            srcSp="/promotion/banner_sp.png"
            alt="知っておきたい店舗マーケティング 2025年版【基本のキ】"
          />
        </NextLink>
        <CloseButton onClick={handleClose}>
          <CancelIcon />
        </CloseButton>
      </CardContainer>
    </Fade>
  );
};

export default FixedBanner;

const CardContainer = styled(Box)`
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  background: #fff;

  @media (max-width: 600px) {
    right: 0;
    bottom: 75px;
    margin: 0 20px;
  }

  @media (min-width: 601px) {
    width: 300px;
    height: 284px;
    bottom: 100px;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  padding: 0;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  background-color: #fff;
  &:hover {
    background-color: #fff;
  }
  @media (max-width: 600px) {
    top: -10px;
    right: 10px;
  }
`;

const ResponsiveImage = styled.img<ResponsiveImageProps>`
  height: auto;
  max-width: 100%;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 600px) {
    content: url(${(props) => props.srcSp});
  }

  @media (min-width: 601px) {
    content: url(${(props) => props.srcPc});
  }
`;